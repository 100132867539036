.App {
  text-align: center;
  position: relative;
  margin: auto;
  width: 800px;
  height: 600px;
  top: 50vh;
  transform: translateY(-60%);
}

.App > img {
  height: 100%;
  width: 100%;
}

.App > .content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

#text {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-transform: uppercase;
  font-weight: bolder;
  color: #6c6c6c;
  letter-spacing: 1px;
}

#intro {
  margin-bottom: 20px;
  font-size: 12px;
}

#names {
  font-size: 30px;
  color: #ff8378;
  letter-spacing: 3px;
}

#date {
  margin-top: 20px;
  font-size: 14px;
}

#rsvpButton {
  position: absolute;
  height: 40px;
  width: 160px;
  top: 70%;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  border-radius: 25px;
  background: #ff8378;
  color: white;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 2px;
  cursor: pointer;
  box-shadow: 0 5px 10px #0004;
  transition: background-color 250ms ease-in-out;
}

#rsvpButton:hover {
  background: #ff7165;
}

#rsvpButton:active {
  margin-top: 0.5px;
}

#carousel {
  display: flex;
  flex-direction: column;
}

#carousel span {
  transition: all 250ms ease-in-out;
  margin-top: -8px;
}

#options {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 90%;
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0 8px 10px #0002;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  overflow: hidden;
  margin-bottom: 20px;
}

#options textarea {
  border: none;
  margin: 0;
  border-bottom: 1px solid #eee;
  padding: 15px 10px 0;
  font-size: 14px;
  text-align: center;
  resize: none;
}

#options #buttons {
  display: flex;
}

#options button {
  height: 40px;
  margin: 0;
  background: white;
  font-weight: 500;
  font-size: 14px;
  flex: 1;
  /*text-transform: uppercase;*/
  color: #6c6c6c;
  letter-spacing: 1px;
}

#options button:hover {
  background: #f8f8f8;
  cursor: pointer;
}

#options button:active {
  /*color: #4c4c4c;*/
  transform: translateY(1px);
}

#yes {
  border-radius: 0 0 0 20px;
  border: none;
}

#maybe {
  border: none;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

#maybe:hover span {
  display: none;
}

#maybe:hover:before {
  content: "Also yes :)";
}

#no {
  border-radius: 0 0 20px 0;
  border: none;
}

#playlist {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  margin: 5px 10px;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: bold;
  color: gray;
}
